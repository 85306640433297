import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "title": "Edit Portfolio" } }, [_c("text-input", { key: "portfolioName", attrs: { "form-item": "", "rules": "required|max:256", "label": "Portfolio", "max-length": 256, "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
render$1._withStripped = true;
const __vue2_script$1 = {
  name: "EditPortfolio",
  data() {
    return {};
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/PortfolioData/EditPortfolioData/EditPortfolioData.vue";
var EditPortfolioData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-portfolio-data" }, [_c("resource", { attrs: { "name": "shipment-closure.portfolio-data", "api-url": _vm.apiUrl, "redirect-route": "/shipment-closure/portfolio-data" } }, [_c("edit-portfolio-data")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditPortfolioData
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/PortfolioData/EditPortfolioData/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
